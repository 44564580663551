import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import detectEthereumProvider from '@metamask/detect-provider'
import ShareLink from 'react-twitter-share-link'
//import ConnectWallet from "thirdweb-dev/react";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const DashboardTop = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {




  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );


//Web3 Code

  const addToMM = async () => {
    const tokenAddress = '0x88F89BE3E9b1dc1C5F208696fb9cABfcc684bD5F';
    const tokenSymbol = 'FLD';
    const tokenDecimals = 18;
    const tokenImage = 'https://snowtrace.io/token/images/ninety1_32.png';

    const provider = await detectEthereumProvider();
    provider.sendAsync({
      method: 'metamask_watchAsset',
      params: {
        "type": "ERC20",
        "options": {
          "address": tokenAddress,
          "symbol": tokenSymbol,
          "decimals": tokenDecimals,
          "image": tokenImage,
        },
      },
      id: Math.round(Math.random() * 100000),
    }, (err, added) => {
      console.log('provider returned', err, added)
      if (err || 'error' in added) {
        console.log('ERROR : There was a problem adding the token.')
        return
      }
      console.log('Token Added.');
    })
}

const claim = async () => {
  console.log('claim');
  props.dividend.methods.claimDividend().send({from: props.publicKey}).then(function (result) {
      console.log(result);
  });;
}

// End Web3 code

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Everburn <span className="text-color-primary">Dashboard</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              HODL, Print, Burn, Earn!
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="#" onClick={addToMM}>
                    Add FLD to Metamask
                    </Button>
                  <Button tag="a" color="dark" wideMobile href="#" onClick={claim}>
                    Claim Rewards
                    </Button>
                    
                    <Button tag="a" color="secondary">
                    
                    <ShareLink link='' text= {`🔥I JUST CLAIMED MY #EVERBURN REWARDS!!🔥                 

I have printed ${props.Reflected} so far HODL'n this GEM!

Total EVB Burned : ${props.burnedToken} 
Total  Distributed  : ${props.totalDistributed} 

🖨🔺 BURN - PRINT - PAY ME! 🔺🖨

#DEFI @EverburnToken`}>
   {link => (
       <a href={link} target='_blank'> Flex on Twitter
       </a>
   )}
   
</ShareLink> </Button>
                </ButtonGroup>
                
              </div>
            </div>
          </div>
          
        
        </div>
      </div>
    </section>
  );
}

DashboardTop.propTypes = propTypes;
DashboardTop.defaultProps = defaultProps;

export default DashboardTop;