import React from 'react';
import classNames from 'classnames';

const FooterSocial = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-social',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a href="https://twitter.com/EverburnToken">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg">
              <title>Twitter</title>
              <path
            d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" />
            </svg>
          </a>
        </li>
        <li>
          <a href="https://medium.com/@everburntoken">
            <svg
              width="16"
              height="16"
              viewBox="0 0 50 50"
              xmlns="http://www.w3.org/2000/svg">
              <title>Medium</title>
              <path d="M15 12a13 13 0 100 26 13 13 0 100-26zm20.5 1c-3.59 0-6.5 5.373-6.5 12 0 1.243.102 2.441.292 3.568.253 1.503.662 2.879 1.192 4.065.265.593.56 1.138.881 1.627.642.978 1.388 1.733 2.202 2.201.611.35 1.26.539 1.933.539s1.322-.189 1.933-.539c.814-.468 1.56-1.223 2.202-2.201.321-.489.616-1.034.881-1.627.53-1.185.939-2.562 1.192-4.065.19-1.127.292-2.325.292-3.568 0-6.627-2.91-12-6.5-12zm10 1c-.259 0-.509.173-.743.495a4.055 4.055 0 00-.448.833c-.071.169-.14.353-.206.551-.133.395-.257.846-.37 1.343-.226.995-.409 2.181-.536 3.497a43.253 43.253 0 00-.146 2.065C43.017 23.499 43 24.241 43 25s.017 1.501.051 2.217c.033.716.082 1.407.146 2.065.127 1.316.31 2.501.536 3.497.113.498.237.948.37 1.343.066.198.135.382.206.551.142.339.292.619.448.833.234.321.484.494.743.494 1.381 0 2.5-4.925 2.5-11s-1.119-11-2.5-11z" />
       </svg>
          </a>
        </li>
        <li>
          <a href="https://t.me/EverburnToken">
            <svg
              width="16"
              height="16"
              viewBox="0 0 50 50"
              xmlns="http://www.w3.org/2000/svg">
              <title>Telegram</title>
              <g>
                <path d="M44.377 5.986c-.487.022-.961.157-1.389.328-.423.17-2.86 1.21-6.457 2.748a20314.52 20314.52 0 00-12.91 5.528c-9.272 3.972-18.383 7.88-18.383 7.88l.067-.025s-.55.184-1.108.573a3.078 3.078 0 00-.824.81 2.145 2.145 0 00-.357 1.432c.237 1.925 2.232 2.47 2.232 2.47l.008.004 8.902 3.047c.227.757 2.7 9.012 3.244 10.76.3.966.582 1.524.875 1.904.147.19.301.338.471.446.068.042.139.075.209.103h.004c.009.004.017.004.025.008l-.023-.006c.016.007.032.018.049.024.031.01.05.01.092.02 1.02.355 1.863-.306 1.863-.306l.035-.027 5.469-5.072 8.875 6.92.111.05c1.553.69 2.958.305 3.736-.328s1.084-1.449 1.084-1.449l.034-.086 6.521-33.99c.167-.76.19-1.418.033-2.012-.157-.594-.54-1.11-1.02-1.396a2.588 2.588 0 00-1.468-.358zm.053 2.034c.197-.01.344.013.39.04.047.028.067.024.112.192.044.167.068.52-.053 1.072l-.004.012-6.484 33.793c-.016.034-.15.347-.46.598-.314.256-.664.456-1.593.066l-9.705-7.568-.274-.215-.005.006-2.903-2.178 16.31-19.19A1 1 0 0038.976 13a1 1 0 00-.53.168L14.844 28.902 5.928 25.85s-.886-.493-.928-.836c-.002-.02-.013-.002.033-.07.046-.07.162-.185.307-.286.29-.202.62-.324.62-.324l.034-.012.033-.013s9.112-3.91 18.383-7.881c4.636-1.986 9.312-3.988 12.908-5.526 3.596-1.537 6.194-2.644 6.414-2.732.25-.1.5-.141.698-.15zM33.613 18.793L21.244 33.346l-.006.006a1 1 0 00-.054.072 1 1 0 00-.055.084 1 1 0 00-.131.385 1 1 0 000 .007l-1.611 7.246c-.027-.078-.046-.106-.075-.199v-.002c-.511-1.644-2.845-9.429-3.167-10.506l17.468-11.646zM22.641 35.73l2.222 1.668-3.265 3.028 1.043-4.696z" />
          </g>
            </svg>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default FooterSocial;