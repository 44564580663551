import React, { useEffect, useState } from 'react';
import Header from '../components/layout/Header';
// import sections
import DashboardTop from '../components/sections/DashboardTop';
import Dashboard from '../components/sections/Dashboard';
// import web3
import { EVB_ABI, EVB_ADDRESS } from '../contracts/evb';
import { DIVIDEND_ABI, DIVIDEND_ADDRESS } from '../contracts/dividendDistributor';
import Web3 from 'web3';


const Home = () => {

  // Web3 Code
const web3 = new Web3(Web3.givenProvider || "https://rinkeby.infura.io/v3/11d2dfe1e20648a7a459f4ef5e57aa2f");
const evb = new web3.eth.Contract(EVB_ABI, EVB_ADDRESS);
const dividend = new web3.eth.Contract(DIVIDEND_ABI, DIVIDEND_ADDRESS)
const usdt = new web3.eth.Contract(EVB_ABI, "0x88F89BE3E9b1dc1C5F208696fb9cABfcc684bD5F");


const [{ metaMaskPresent, metaMaskConnected }, setMetaMaskObject] = useState({
  metaMaskPresent: false,
  metaMaskConnected: false
});
const [publicKey, setPublicKey] = useState("Connect Wallet"); // My address
  const [currentBalance, setCurrentBalance] = useState(0); 
  const [UnpaidEarnings, setUnpaidEarnings] = useState(0); 
  const [rewardPercentByHoldings, setRewardPercentByHoldings] = useState(0);  
  const [circulatingSupply, setCirculatingSupply] = useState(0); 
  const [burnedToken, setBurnedToken] = useState(0); 
  const [totalDistributed, setTotalDistributed] = useState(0);
  const [Reflected, setReflected] = useState(0); 
  const [usdtBalance, setusdtBalance] = useState(0); 
  const [BurnedPerHour, setBurnedPerHour] = useState(0); 

  const connectMetaMask = async () => {
    try {
      await web3.currentProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0xA86A" }]
      });
    } catch (error) {
      alert(error.message);
    }
    const accounts = await web3.eth.requestAccounts();
    setPublicKey(accounts[0]);
    getBalance(accounts[0]);
    getUnpaid(accounts[0]);
   
    getRewardPercentByHoldings(accounts[0]);
    getusdtBalance(accounts[0]);
   getReflected(accounts[0]);
  };

 
  const inittotal = () => {
    setCurrentBalance('**Connect Metamask**')
    setusdtBalance('**Connect Metamask**')
    setReflected('**Connect Metamask**')
    setUnpaidEarnings('**Connect Metamask**')
    setBurnedPerHour('**Connect Metamask**')
    setRewardPercentByHoldings('**Connect Metamask**')
    setCurrentBalance('**Connect Metamask**');
    setUnpaidEarnings('**Connect Metamask**');
    setRewardPercentByHoldings('**Connect Metamask**');
    setCirculatingSupply('**Connect Metamask**');
    setBurnedToken('**Connect Metamask**');
    setTotalDistributed('**Connect Metamask**');
  }

  const update = async () => {
    const accounts = await web3.eth.requestAccounts(); 
    setPublicKey(accounts[0]);
    getCirculatingSupply();
    getBurnedToken();
    getTotalDistributed();
    getBurnedPerHour();
    getUnpaid(accounts[0]);
    getBalance(accounts[0]);
    getUnpaid(accounts[0]);
    getRewardPercentByHoldings(accounts[0]);
    getusdtBalance(accounts[0]);
   getReflected(accounts[0]);
  }


  const getBalance = (_publicKey) => {
    let BalanceFormated = 0;
    evb.methods.balanceOf(_publicKey).call().then(function (result) {
      BalanceFormated = result/1000000;
      BalanceFormated = BalanceFormated.toLocaleString();
      setCurrentBalance('>' + BalanceFormated + ' EVB');
    });
  }


  const getBurnedPerHour = () => {
  //   let BurnedPerHour= 0;
  //   let BurnedTotal = 0;
  //   const DEAD = '0x000000000000000000000000000000000000dEaD';
  //   evb.methods.balanceOf(DEAD).call().then(function (result){
  //   BurnedTotal = result/1000000;
  //   var launchTime = new Date('03/04/2022 22:00:00');  
  //   var thisTime = new Date();              // now
  //   var diffindays = parseInt((thisTime - launchTime) / (1000 * 60 * 60 * 24), 10); 
  //   BurnedPerHour = BurnedTotal / diffindays;
  //   BurnedPerHour = BurnedPerHour.toLocaleString(3);
  //   setBurnedPerHour( 'Unknown EVB / Day');
  //  


      let BurnedPerHour = 0;
      dividend.methods.totalShares().call().then(function (result) {
      
        BurnedPerHour = result/1000000;
        BurnedPerHour = BurnedPerHour.toLocaleString();
        setBurnedPerHour(BurnedPerHour + ' EVB');
      })
    }





  const getusdtBalance = (_publicKey) => {
    let USDTBalanceFormated = 0;
    usdt.methods.balanceOf(_publicKey).call().then(function (result) {
        USDTBalanceFormated = result / 1000000000000000000;
      USDTBalanceFormated = USDTBalanceFormated.toFixed(2);
      setusdtBalance('>' + USDTBalanceFormated + ' FLD');
    });
  }

  const getUnpaid = (_publicKey) => {
    let UnpaidFormated = 0;
    dividend.methods.getUnpaidEarnings(_publicKey).call().then(function (result) {
        UnpaidFormated = result / 1000000000000000000;
      UnpaidFormated = UnpaidFormated.toFixed(2)
      setUnpaidEarnings('>' + UnpaidFormated + ' FLD');
    });
  }


  const getReflected = (_publicKey) => {
    let reflectedtotal = 0
    let ReflectedFormated = 0;
    dividend.methods.shares(_publicKey).call().then(function (result)   
    {
      reflectedtotal = JSON.parse(result.totalRealised)
        ReflectedFormated = reflectedtotal / 1000000000000000000;
      ReflectedFormated = ReflectedFormated.toLocaleString(undefined, {maximumFractionDigits:2})
      setReflected('>' + ReflectedFormated + ' FLD');
    });
  }



  const getCirculatingSupply = () => {
    let SupplyFormated = 0;
    evb.methods.getCirculatingSupply().call().then(function (result) {
    SupplyFormated = result/1000000;
    SupplyFormated = SupplyFormated.toLocaleString();
        setCirculatingSupply(SupplyFormated + ' EVB');
    });
}


  const getBurnedToken = () => {
    let BurnedFormated = 0;
    const DEAD = '0x000000000000000000000000000000000000dEaD';
    evb.methods.balanceOf(DEAD).call().then(function (result) {
    BurnedFormated = result/1000000;
    BurnedFormated = BurnedFormated.toLocaleString();
        setBurnedToken(BurnedFormated + ' EVB');
    });
    }

  const getRewardPercentByHoldings = async (_publicKey) => {
    let circulatingSupply = 0;
    await dividend.methods.totalShares().call().then(function (result) {
      circulatingSupply = result;
    });
    evb.methods.balanceOf(_publicKey).call().then(function (result) {
      
      if ( circulatingSupply != 0 ) {
        setRewardPercentByHoldings((result / circulatingSupply * 100).toFixed(4) + "%");
      } else {
        setRewardPercentByHoldings(0);
      }
    });
  }


  const getTotalDistributed = () => {
    let TotalFormated = 0;
    dividend.methods.totalDistributed().call().then(function (result) {
        TotalFormated = result / 1000000000000000000;
    TotalFormated = TotalFormated.toLocaleString(undefined, {maximumFractionDigits:2}); 
        setTotalDistributed('>'+ TotalFormated + ' FLD');
    });
    }



    useEffect(() => {
      const isMetaMaskPresent = () => {
        return web3?.givenProvider?.isMetaMask ? true : false;
      };
      setMetaMaskObject(() =>
        isMetaMaskPresent()
          ? { metaMaskPresent: true, metaMaskConnected }
          : { metaMaskPresent: false, metaMaskConnected }
      );
    }, [web3?.givenProvider?.isMetaMask, metaMaskConnected]);
    
    useEffect(()=> {
      getCirculatingSupply();
      getBurnedToken();
      getTotalDistributed();
      getBurnedPerHour();
      inittotal();
    } ,[])

    useEffect(() => {
      update()
     }, []);
    setInterval(update, 10000);

// End Web3 Code

  return (
    <>
      <Header 
        connectMetaMask={connectMetaMask} 
        metaMaskPresent={metaMaskPresent} 
        metaMaskConnected={metaMaskConnected} 
        publicKey={publicKey}

      navPosition="right" className="reveal-from-bottom" />
      <DashboardTop
            evb={evb} 
            dividend={dividend} 
            publicKey={publicKey} 
            Reflected={Reflected}
            totalDistributed={totalDistributed}
            burnedToken={burnedToken}
className="illustration-section-01" />

      
      <Dashboard 
            evb={evb} 
            dividend={dividend} 
            publicKey={publicKey} 
            currentBalance={currentBalance}
            UnpaidEarnings={UnpaidEarnings}
            rewardPercentByHoldings={rewardPercentByHoldings}
            burnedToken={burnedToken}
            circulatingSupply={circulatingSupply}
            totalDistributed={totalDistributed}
            usdtBalance={usdtBalance}
            Reflected={Reflected}
            BurnedPerHour={BurnedPerHour}
      />
  
      
 


    </>
  );
}

export default Home;