import React from 'react';
import classNames from 'classnames';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a href="http://www.everburn.io/">Home</a>
        </li>
        <li>
        <a href="http://traderjoexyz.com/avalanche/trade?inputCurrency=AVAX&outputCurrency=0xebe2eae72D6eAA44A3bCA32cFDF81D3A687917c2#/">Trade</a>
        </li>
        <li>
        <a href="https://www.everburn.io/whitepaper-v2.pdf">Whitepaper</a>
        </li>
 
      </ul>
    </nav>
  );
}

export default FooterNav;